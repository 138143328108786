define(['$'], function($) {
  var events = $({});
  var delimeter = '~';

  function createHandler(eventName, callback) {
    return function(evData) {
      evData.type = eventName;
      callback.apply(this, arguments);
    };
  }

  function getNewEventName(eventName) {
    return eventName.replace(/\./g, delimeter);
  }

  return {
    on: function(eventName, callback) {
      var eventHandler = createHandler(eventName, callback);
      events.on(getNewEventName(eventName), eventHandler);
      return eventHandler;
    },
    trigger: function(eventName, data) {
      return events.trigger(getNewEventName(eventName), data);
    },
    off: function(eventName) {
      var args = Array.prototype.slice.call(arguments, 1);
      args.splice(0, 0, getNewEventName(eventName));
      return events.off.apply(events, args);
    },
    one: function(eventName, callback) {
      var eventHandler = createHandler(eventName, callback);
      events.one(getNewEventName(eventName), eventHandler);
      return eventHandler;
    }
  };
});
